<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.spotLoads')}}
                                        <span v-if="pagination.total">({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-0">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('spotloadsview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.exportExcel')"
                                                      variant="outline-info"
                                                      @click="handleExcelExportClick" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('spotloadsview')">
                                                <i class="fa fa-file-excel-o"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>

                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div>
            <div class="card-body">
                <div class="supplier-spot-table" v-show="!operation">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(id)="{ item, index}">
                            {{ (pagination.perPage * (pagination.current-1)) + (index + 1) }}
                        </template>
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{record.item.supplier_carrier ? record.item.supplier_carrier.name : ''}}
                        </template>
                        <template v-slot:cell(client_id)="record">
                            {{record.item.client ? record.item.client.company_name : ''}}
                        </template>
                        <template v-slot:cell(deliver_to)="record">
                            <span v-if="record.item.deliver_to === 1">{{$t('title.deliverToCompound')}}</span>
                            <span v-if="record.item.deliver_to === 2">{{$t('title.deliverToDealer')}}</span>
                            <span v-if="record.item.deliver_to === 3">{{$t('title.default')}}</span>
                            <div v-if="record.item.deliver_to === 2">{{(record.item.dealer || {}).dealer_id}}
                                {{(record.item.dealer || {}).name}}
                            </div>
                            <div v-if="record.item.deliver_to === 1">{{(record.item.supplier_compound || {}).name}}
                            </div>
                        </template>
                        <template v-slot:cell(from_location_id)="{item}">
                            <span v-if="item.spot_load_orders[0] && item.spot_load_orders[0].from_location">
                                <print-ad
                                    :item="item.spot_load_orders[0] && item.spot_load_orders[0].from_location"
                                    titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(to_location_id)="{item}">
                            <span v-if="item.spot_load_orders[0] && item.spot_load_orders[0].to_location">
                                <print-ad
                                    :item="item.spot_load_orders[0] && item.spot_load_orders[0].to_location"
                                    titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(driver_id)="record">
                            {{record.item.driver ? record.item.driver.name : ''}}
                        </template>
                        <template v-slot:cell(loading_date)="record">
                            <span v-if="record.item.actual_loading_time">
                                {{$global.utcDateToLocalDate(record.item.actual_loading_time) }}
                            </span>
                        </template>
                        <template v-slot:cell(status)="record">
                            <div>
                                <re-status :status="record.item.status"></re-status>
                            </div>
                            <ibla-status v-if="(record.item.approval_status < 3)" :status="record.item.approval_status"
                                         :comment="(record.item.load_approval || {}).comments"></ibla-status>
                        </template>
                        <template v-slot:cell(updated_at)="record">
                            {{$global.utcDateToLocalDate(record.item.updated_at) }}
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')"
                               v-if="$global.hasPermission('spotloadsupdate') && $global.hasAnyRole(['superadmin', 'efl'])
                                && record.item.status < 4" class="ml-1"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a @click="handleLoadApprovalClick(record.item.id)"
                               v-if="(record.item.approval_status === 1 || record.item.approval_status === 2) && record.item.can_approve"
                               :title="$t('button.title.loadApproval')"
                               class="ml-1" v-b-tooltip.hover>
                                <i class="fe fe-check-circle"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('spotloadsdestroy') && record.item.status <= 3">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class="ml-1" :title="$t('button.title.deleteItem')" v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-if="$global.hasPermission('spotloadsdetail') && $global.hasAnyRole(['superadmin', 'efl', 'efluser'])"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>

                            <a @click="handleAcceptClick(record.item)"
                               v-if="record.item.status <= 2 && _.includes([3,4], record.item.approval_status)"
                               :title="$t('button.title.acceptLoad')"
                               v-b-tooltip.hover class="text-success ml-1">
                                <i class="fe fe-check-square"></i>
                            </a>
                            <a-popconfirm title="Are you sure？"
                              @confirm="handleSpotLoadResetClick(record.item)"
                              v-if="record.item.status === 4 && !record.item.had_reset && !record.item.edited && _.includes([3,4], record.item.approval_status)">
                                <a :title="$t('button.title.reset')"
                                   v-b-tooltip.hover class="text-danger ml-2">
                                    <i class="fe fe-refresh-ccw"></i>
                                </a>
                            </a-popconfirm>
                            <a @click="handleInTransitClick(record.item)"
                               v-if="record.item.status >=4 && record.item.status <= 5 && _.includes([3,4], record.item.approval_status)"
                               :title="$t('button.title.inTransit')"
                               v-b-tooltip.hover class="ml-2 text-success">
                                <i class="fe fe-truck"></i>
                            </a>
                            <a @click="handleUnLoadClick(record.item)"
                               v-if="record.item.status == 6 && !record.item.is_d_unloaded && _.includes([3,4], record.item.approval_status)"
                               :title="$t('button.title.confirmUnload')" v-b-tooltip.hover
                               class="ml-2 text-warning">
                                <i class="fe fe-truck"></i>
                            </a>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.spot-table-->

                <div class="spot-operation">
                    <div v-if="operation === 'edit'">
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>

                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <b-col sm="12" lg="4" md="4">
                                                    <b-form-group
                                                        :label="$t('input.deliverTo')+' *'"
                                                        label-for="deliver_to"
                                                        :invalid-feedback="formErrors.first('deliver_to')"
                                                    >
                                                        <treeselect
                                                            :multiple="false"
                                                            :options="dropdowns.deliveryTypes"
                                                            :disabled="true"
                                                            placeholder=""
                                                            v-model="formFields.deliver_to"
                                                            :class="[{'invalid is-invalid': (formErrors.first('deliver_to'))}]"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="12" lg="4" md="4" v-if="formFields.deliver_to == 1">
                                                    <b-form-group
                                                        :label="$t('input.supplierCompound')+' *'"
                                                        label-for="supplier_compound_id"
                                                        :invalid-feedback="formErrors.first('supplier_compound_id')"
                                                    >
                                                        <treeselect
                                                            :multiple="false"
                                                            :options="dropdowns.supplierCompounds"
                                                            :disabled="formFields.status >= 4"
                                                            placeholder=""
                                                            v-model="formFields.supplier_compound_id"
                                                            :class="[{'invalid is-invalid': (formErrors.first('supplier_compound_id'))}]"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="12" lg="4" md="4" v-if="formFields.deliver_to == 2">
                                                    <b-form-group
                                                        :label="$t('input.dealer')+' *'"
                                                        label-for="dealer_id"
                                                        :invalid-feedback="formErrors.first('dealer_id')"
                                                    >
                                                        <treeselect
                                                            :multiple="false"
                                                            :options="formFields.dealers"
                                                            :disabled="formFields.status >= 4"
                                                            placeholder=""
                                                            v-model="formFields.dealer_id"
                                                            :class="[{'invalid is-invalid': (formErrors.first('dealer_id'))}]"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </div>
                                        </div>

                                        <b-col md="4" lg="4" sm="12">
                                            <b-form-group
                                                :label="$t('input.supplierCarrier')+' *'"
                                                label-for="supplier_id"
                                                :invalid-feedback="formErrors.first('supplier_carrier_id')"
                                            >
                                                <treeselect
                                                    :multiple="false"
                                                    :disabled="formFields.status >= 4"
                                                    :options="dropdowns.supplierCarriers"
                                                    placeholder=""
                                                    v-model="formFields.supplier_carrier_id"
                                                    :class="[{'invalid is-invalid': (formErrors.first('supplier_carrier_id'))}]"
                                                />
                                            </b-form-group>
                                        </b-col>

                                        <b-col md="4" lg="4" sm="12">
                                            <b-form-group
                                                :label="$t('input.currency')+' *'"
                                                label-for="currency"
                                                :invalid-feedback="formErrors.first('currency')"
                                            >
                                                <treeselect
                                                    required
                                                    :multiple="false"
                                                    :options="dropdowns.currencies"
                                                    placeholder=""
                                                    v-model="formFields.currency"
                                                    :class="[{'invalid is-invalid': (formErrors.first('currency'))}]"
                                                />
                                            </b-form-group>
                                        </b-col>

                                        <b-col md="4" lg="4" sm="12">
                                            <div class="form-group mb-1">
                                                <label class="d-block">{{$t('input.supervisor')}} *</label>
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.supervisors"
                                                    placeholder=""
                                                    v-model="formFields.load_approver_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('load_approver_id'))}]"
                                                />
                                                <div class="invalid-feedback">{{formErrors.first('load_approver_id')}}
                                                </div>
                                            </div><!-- /.form-group -->
                                        </b-col>

                                        <b-col md="4" lg="4" sm="12">
                                            <div class="form-group">
                                                <div class="clearfix">
                                                    <span class="pull-left">
                                                        <label class="d-block">{{$t('input.order')}}</label>
                                                    </span><!-- /.pull-left -->
                                                    <span class="pull-left ml-2 mb-1">
                                                        <button class="btn btn-warning btn-sm" v-b-tooltip.hover
                                                                :title="$t('button.title.addNewOrder')" type="button"
                                                                @click="handleAddOrderClick">{{$t('button.add')}}</button>
                                                    </span><!-- /.pull-left -->
                                                </div><!-- /.clearfix -->
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.orders"
                                                    placeholder=""
                                                    v-model="selectedOrderId"
                                                    :class="[{'invalid is-invalid': (formErrors.has('order_id'))}]"/>
                                                <div class="invalid-feedback">{{formErrors.first('order_id')}}</div>
                                            </div>
                                        </b-col>

                                        <b-col md="6" lg="6" sm="12">
                                            <b-form-group
                                                :label="$t('input.comment')"
                                                label-for="comment"
                                                :invalid-feedback="formErrors.first('comments')">
                                                <b-textarea
                                                    id="comment"
                                                    v-model="formFields.comments"
                                                    type="text"
                                                    :state="((formErrors.has('comments') ? false : null))"
                                                    @focus="$event.target.select()"
                                                ></b-textarea>
                                            </b-form-group>
                                        </b-col>

                                        <b-col sm="4" v-if="formFields.status == 3">
                                            <b-form-group
                                                :label="$t('input.status') + ':'"
                                                :invalid-feedback="formErrors.first('announce')"
                                            >
                                                <b-form-checkbox v-model="formFields.announce" name="announce">
                                                    {{$t('title.announce')}}
                                                </b-form-checkbox>
                                            </b-form-group>
                                        </b-col>

                                        <b-col sm="12">
                                            <h3 class="text-secondary">{{$t('title.existingOrders')}}</h3>
                                            <hr/>

                                            <small class="text-danger" v-if="formErrors.has('orders')">{{formErrors.first('orders')}}</small>

                                            <table class="table table-hover"
                                                   v-if="editLoad && editLoad.spot_load_orders">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th width="100">{{$t('column.orderId')}}</th>
                                                    <th width="200">{{$t('column.client')}}</th>
                                                    <th width="100">{{$t('column.brand')}}</th>
                                                    <th width="100">{{$t('column.model')}}</th>
                                                    <th>{{$t('column.fromLocation')}}</th>
                                                    <th width="400">{{$t('column.toLocation')}}</th>
                                                    <th width="200">{{$t('column.price')}}</th>
                                                    <th width="100">{{$t('column.action')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(ilo, index) in formFields.orders"
                                                    :class="{'bg-light-danger': _.includes(formFields.deleted_orders, ilo.order.id)}">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{ilo.order.order_id}}</td>
                                                    <td>{{(ilo.client || {}).company_name}}</td>
                                                    <td>{{(ilo.brand || {}).title}}</td>
                                                    <td>
                                                        <div>{{(ilo.model || {}).title}}</div>
                                                        <div>{{(ilo.model || {}).version_code}}</div>
                                                    </td>
                                                    <td>
                                                        <print-ad :item="ilo.from_location" titled="1"></print-ad>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            label-for="location_id"
                                                            :invalid-feedback="formErrors.first('location_id')"
                                                        >
                                                            <treeselect
                                                                :multiple="false"
                                                                :options="dropdowns.locations"
                                                                placeholder=""
                                                                v-model="formFields.orders[index].to_location_id"
                                                                :class="[{'invalid is-invalid': (formErrors.has('location_id'))}]"
                                                            />
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            label-for="price"
                                                            :invalid-feedback="formErrors.first('price')"
                                                        >
                                                            <b-form-input
                                                                id="price"
                                                                v-model="formFields.orders[index].price"
                                                                type="number"
                                                                :state="((formErrors.has('price') ? false : null))"
                                                                @focus="$event.target.select()"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                        <a-popconfirm title="Are you sure？"
                                                                      @confirm="handleDeleteOrderClick(ilo.order.id)"
                                                                      v-if="!_.includes(formFields.deleted_orders, ilo.order.id)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                        <template v-if="formFields.new_orders.length <= 0">
                                                            <a class="ml-1"
                                                               v-if="_.includes(formFields.deleted_orders, ilo.order.id) && formFields.new_orders.length <= 0"
                                                               @click="handleUndoDeleteClick(ilo.order.id)"
                                                               :title="$t('button.title.undo')"
                                                               v-b-tooltip.hover>
                                                                <i class="fa fa-undo"></i>
                                                            </a>
                                                        </template>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <h3 class="text-secondary">{{$t('title.newOrders')}}</h3>
                                            <hr/>

                                            <small class="text-danger" v-if="formErrors.has('new_orders')">{{formErrors.first('new_orders')}}</small>
                                            <table class="table table-hover"
                                                   v-if="editLoad && formFields.new_orders.length">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th width="100">{{$t('column.orderId')}}</th>
                                                    <th width="200">{{$t('column.client')}}</th>
                                                    <th width="100">{{$t('column.brand')}}</th>
                                                    <th width="100">{{$t('column.model')}}</th>
                                                    <th>{{$t('column.fromLocation')}}</th>
                                                    <th width="400">{{$t('column.toLocation')}}</th>
                                                    <th width="200">{{$t('column.price')}}</th>
                                                    <th width="100">{{$t('column.action')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(ilo, index) in formFields.new_orders">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{ilo.order.order_id}}</td>
                                                    <td>{{(ilo.client || {}).company_name}}</td>
                                                    <td>{{(ilo.brand || {}).title}}</td>
                                                    <td>{{(ilo.model || {}).title}}</td>
                                                    <td>
                                                        <print-ad :item="ilo.from_location" titled="1"></print-ad>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            label-for="location_id"
                                                            :invalid-feedback="formErrors.first('location_id')"
                                                        >
                                                            <treeselect
                                                                :multiple="false"
                                                                :options="dropdowns.locations"
                                                                placeholder=""
                                                                v-model="formFields.new_orders[index].to_location_id"
                                                                :class="[{'invalid is-invalid': (formErrors.has('location_id'))}]"
                                                            />
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            label-for="price"
                                                        >
                                                            <b-form-input
                                                                id="price"
                                                                v-model="formFields.new_orders[index].price"
                                                                type="number"
                                                                :state="((formErrors.has('price') ? false : null))"
                                                                @focus="$event.target.select()"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                        <a-popconfirm title="Are you sure？"
                                                                      @confirm="handleDeleteNewOrderClick(ilo.id)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <hr/>

                                            <b-row class="operation-footer">
                                                <b-col sm="12">
                                                    <b-button
                                                        size="sm"
                                                        type="submit"
                                                        variant="primary"
                                                        :disabled="global.pendingRequests > 0"
                                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                                    >
                                                        <clip-loader style="display: inline" :loading="true"
                                                                     color="#fff"
                                                                     size="12px"
                                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                                        <i class="fa fa-save mr-1"></i>
                                                        {{$t('button.save')}}
                                                    </b-button>
                                                    <b-button variant="warning" class="ml-3"
                                                              size="sm" @click="handleOperationClose()"
                                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </form>
                    </div>
                </div><!--/.spot-operation-->

                <div class="spot-load-approval">
                    <a-drawer
                        placement="right"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="spotLoadApprovalFormFields.id = null"
                        :visible="!operation && !(!spotLoadApprovalFormFields.id)"
                        :zIndex="10"
                        :title="$t('title.loadApproval')"
                    >
                        <form @submit.prevent="handleLoadApprovalSubmit" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')"
                                        label-for="status"
                                        :invalid-feedback="formErrors.first('status')"
                                    >
                                        <treeselect
                                            :multiple="false"
                                            :options="[{id:2,label:'Load Approval Rejected'},{id:3,label:'Load Approval Confirmed'}]"
                                            placeholder=""
                                            v-model="spotLoadApprovalFormFields.status"
                                            :class="[{'invalid is-invalid': (formErrors.has('status'))}]"/>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.comment')"
                                        label-for="comments"
                                        :invalid-feedback="formErrors.first('comments')"
                                    >
                                        <b-textarea
                                            id="comments"
                                            v-model="spotLoadApprovalFormFields.comments"
                                            :state="((formErrors.has('comments') ? false : null))"
                                            @focus="$event.target.select()"
                                        ></b-textarea>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="primary"
                                          :disabled="global.pendingRequests > 0"
                                          button="submit" type="filled"
                                          :title="$t('button.title.update')" v-b-tooltip.hover>
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                                    {{$t('button.update')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.spot-load-approval -->

                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.client')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.clients"
                                            placeholder=""
                                            v-model="filters.clients"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.carriers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.supplierCarriers"
                                            placeholder=""
                                            v-model="filters.supplierCarriers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.code')">
                                        <treeselect
                                            :multiple="true"
                                            :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                            placeholder=""
                                            v-model="filters.configCode"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.loadingPoint"
                                            placeholder=""
                                            v-model="filters.loadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.unloadingPoint"
                                            placeholder=""
                                            v-model="filters.unloadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.drivers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.drivers"
                                            placeholder=""
                                            v-model="filters.drivers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.vehicles')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.vehicles"
                                            placeholder=""
                                            v-model="filters.vehicles"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div>
        </div>
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>

        <!-- # Excel Export -->
        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                        <hr>
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('title.columns')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(column, index) in exportModal.columns">
                                <td>{{index + 1}}</td>
                                <td>
                                    <b-form-checkbox v-model="column.show"
                                                     name="check-button">{{column.label}}
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="Orders"
                            :fetch="handleExcelExportSubmitClick"
                            name="sopt_loads.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->

        <!-- # Status Update -->
        <a-drawer
            placement="right"
            :width="'90%'"
            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
            :closable="false"
            @close="handleStatusOperationReset"
            :visible="selectedLoad !== null"
            :zIndex="10"
        >
            <template slot="title">
                {{title}}
                <span class="text-dark" v-if="selectedLoad">
                    <span class="badge badge-dark">
                        <strong>{{(selectedLoad || {}).load_number}}</strong>
                    </span>
                </span>
            </template>
            <form @submit.prevent="handleStatusSubmit" autocomplete="off">
                <b-row class="mb-3">
                    <b-col md="3" lg="3" sm="12" v-if="statusFormFields.status === 4">
                        <div class="form-group">
                            <div class="clearfix">
                                <span class="pull-left">
                                    <label for="driver_id" class="d-block">
                                        {{$t('input.driver')}} *
                                    </label>
                                </span><!-- /.pull-left -->
                                <span class="pull-right">
                                    <quick-driver-form
                                        allow-update="1"
                                        :supplier="statusFormFields.supplier"
                                        allow-create="1"
                                        :id="statusFormFields.driver_id"
                                        :after-create="handleAfterQuickDriverCreated"
                                        :after-update="handleAfterQuickDriverUpdated">
                                    </quick-driver-form>
                                </span><!-- /.pull-right -->
                            </div><!-- /.clearfix -->
                            <treeselect
                                :multiple="false"
                                :options="dropdowns.supplierDrivers"
                                placeholder=""
                                v-model="statusFormFields.driver_id"
                                :class="[{'invalid is-invalid': (formErrors.has('driver_id'))}]"
                            />
                            <div class="invalid-feedback">{{formErrors.first('driver_id')}}</div>
                        </div><!-- /.form-group -->
                    </b-col>
                    <b-col md="3" lg="3" sm="12" v-if="statusFormFields.status === 4">
                        <div class="form-group">
                            <div class="clearfix">
                                <span class="pull-left">
                                    <label for="transport_vehicle_id" class="d-block">
                                        {{$t('input.transportVehicle')}} *
                                    </label>
                                </span><!-- /.pull-left -->
                                <span class="pull-right">
                                    <quick-transport-vehicle-form
                                        allow-update="1"
                                        allow-create="1"
                                        :supplier="statusFormFields.supplierTransportVehicles"
                                        :id="statusFormFields.transport_vehicle_id"
                                        :after-create="handleAfterQuickTransportVehicleCreated"
                                        :after-update="handleAfterQuickTransportVehicleUpdated">
                                    </quick-transport-vehicle-form>
                                </span><!-- /.pull-right -->
                            </div><!-- /.clearfix -->
                            <treeselect
                                :multiple="false"
                                :options="dropdowns.supplierTransportVehicles"
                                placeholder=""
                                v-model="statusFormFields.transport_vehicle_id"
                                :class="[{'invalid is-invalid': (formErrors.has('transport_vehicle_id'))}]"
                            />
                            <div class="invalid-feedback">
                                {{formErrors.first('transport_vehicle_id')}}
                            </div>
                        </div><!-- /.form-group -->
                    </b-col>
                    <b-col md="3" lg="3" sm="12" v-if="statusFormFields.status === 4">
                        <b-form-group
                            :label="$t('input.estimatedLoadingTime')+' *'"
                            label-for="driver_id"
                            :class="{'has-error': formErrors.has('estimated_loading_time')}">
                            <a-date-picker
                                :show-time="{ format: 'HH:mm' }"
                                placeholder=""
                                format="DD-MM-YYYY hh:mm A"
                                v-model="statusFormFields.estimated_loading_time"
                            />
                            <div class="invalid-feedback">{{formErrors.first('estimated_loading_time')}}
                            </div>
                        </b-form-group>
                    </b-col><!--/b-col-->
                    <b-col md="3" lg="3" sm="12" v-if="statusFormFields.status === 4">
                        <b-form-group
                                :label="$t('input.estimatedUnloadingTime')"
                                label-for="estimatedUnloadingTime"
                                :invalid-feedback="formErrors.first('estimated_unloading_time')">
                            <a-date-picker
                                :show-time="{ format: 'HH:mm' }"
                                placeholder=""
                                format="DD-MM-YYYY hh:mm A"
                                v-model="statusFormFields.estimated_unloading_time"
                            />
                        </b-form-group>
                    </b-col><!--/b-col-->
                    <b-col md="3" lg="3" sm="12" v-if="statusFormFields.status >= 6">
                        <b-form-group
                                :label="$t('input.dateTime')"
                                label-for="dateTime"
                                :class="{'has-error': formErrors.has('created_at')}">
                            <a-date-picker
                                :show-time="{ format: 'HH:mm' }"
                                placeholder=""
                                format="DD-MM-YYYY hh:mm A"
                                v-model="statusFormFields.created_at"
                                :class="[{'invalid is-invalid': (formErrors.has('created_at'))}]"
                            />
                            <div class="invalid-feedback">{{formErrors.first('created_at')}}</div>
                        </b-form-group>
                    </b-col>

                    <b-col sm="12">
                        <b-form-group
                                :label="$t('input.comment')+(statusFormFields.status === 3 ? ' *' : '')"
                                label-for="comment"
                                :invalid-feedback="formErrors.first('comments')">
                            <b-textarea
                                    id="comment"
                                    v-model="statusFormFields.comments"
                                    type="text"
                                    :state="((formErrors.has('comments') ? false : null))"
                                    @focus="$event.target.select()"
                            ></b-textarea>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" v-if="selectedLoad && selectedLoad.spot_load_orders">
                        <h4 class="text-secondary">
                            {{$t('title.orders')}}
                        </h4>
                        <h6>
                            <span class="text-secondary" v-if="selectedLoad.spot_load_orders[0]">
                                <span v-if="selectedLoad.spot_load_orders[0].from_location.city">{{selectedLoad.spot_load_orders[0].from_location.city}}, </span>
                                (<span v-if="selectedLoad.spot_load_orders[0].from_location.country">{{selectedLoad.spot_load_orders[0].from_location.country}}</span>)
                            </span> -
                            <span class="text-secondary" v-if="selectedLoad.spot_load_orders[0]">
                                <span v-if="selectedLoad.spot_load_orders[0].to_location.city">{{selectedLoad.spot_load_orders[0].to_location.city}}, </span>
                                (<span v-if="selectedLoad.spot_load_orders[0].to_location.country">{{selectedLoad.spot_load_orders[0].to_location.country}}</span>)
                            </span>
                        </h6>
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{$t('column.brand')}}</th>
                                        <th>{{$t('column.model')}}</th>
                                        <th>{{$t('column.orderId')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ilo, index) in selectedLoad.spot_load_orders">
                                        <td>{{index + 1}}</td>
                                        <td>{{ilo.order.brand.title}}</td>
                                        <td>{{ilo.order.model.title}}</td>
                                        <td>{{ilo.order.order_id}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-col>
                </b-row>
                <div class="drawer-footer">
                    <b-button variant="warning" @click="handleStatusOperationReset" class="mr-2"
                              size="sm" :title="$t('button.title.cancel')" v-b-tooltip.hover>
                        {{$t('button.cancel')}}
                    </b-button>
                    <b-button variant="primary" button="submit" type="filled"
                              :disabled="global.pendingRequests > 0" size="sm"
                              :title="$t('button.title.update')" v-b-tooltip.hover>
                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                     size="12px"
                                     v-if="global.pendingRequests > 0"></clip-loader>
                        {{$t('button.update')}}
                    </b-button>
                </div><!-- /.drawer-footer -->
            </form>
        </a-drawer>
    </div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import qs from "qs"
    import LoadApprovalMixin from "./LoadApprovalMixin";
    import ExcelExportMixin from "./ExcelExportMixin";
    import JsonExcel from "vue-json-excel"
    import StatusUpdateMixin from "./StatusUpdateMixin";
    import QuickTransportVehicleForm from "../transportVehicles/QuickTransportVehicleForm";
    import QuickDriverForm from "../drivers/QuickDriverForm";

    const FORM_STATE = {
        supplier_compound_id: null,
        dealer_id: null,
        supplier_carrier_id: null,
        load_approver_id: null,
        to_location_id: null,
        id: null,
        comments: null,
        price: 0,
        currency: null,
        approval_status: null,
        spot_load_orders: [],
        status: 2,
        announce: false,
        _method: 'post',
        deleted_orders: [],
        new_orders: [],
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        drivers: [],
        vehicles: [],
        status: [1, 2, 3, 4, 5, 6, 7],
        loadingPoint: [],
        unloadingPoint: [],
        clients: [],
        supplierCarriers: [],
        supplierCompounds: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.supplierCarrier'),
            key: 'supplier_carrier_id',
            sortable: true,
        },
        {
            label: self.$t('column.deliverTo'),
            key: 'deliver_to',
            sortable: true,
        },
        {
            label: self.$t('column.driver'),
            key: 'driver_id',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.qty'),
            key: 'qty',
            sortable: true,
        },
        {
            label: self.$t('column.loadingDate'),
            key: 'loading_date',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['spotloadsupdate', 'spotloadsview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right w-100px',
                key: 'action',
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, LoadApprovalMixin, ExcelExportMixin, StatusUpdateMixin],
        components: {
            Treeselect,
            Detail,
            JsonExcel,
            QuickDriverForm,
            QuickTransportVehicleForm
        },
        data() {
            return {
                operationTitle: 'title.spotLoads',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'spot/loads',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    clients: [],
                    supplierCarriers: [],
                    supplierCompounds: [],
                    dealers: [],
                    locations: [],
                    orders: [],
                    countries: [],
                    currencies: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    drivers: [],
                    vehicles: [],
                    supervisors: [],
                    deliveryTypes: [
                        {id: 1, label: this.$t('title.deliverToCompound')},
                        {id: 2, label: this.$t('title.deliverToDealer')},
                        {id: 3, label: this.$t('title.default')},
                    ],
                    status: [
                        {id: 1, label: this.$t('title.created')},
                        {id: 2, label: this.$t('title.announced')},
                        {id: 3, label: this.$t('title.rejected')},
                        {id: 4, label: this.$t('title.confirmed')},
                        {id: 5, label: this.$t('title.loaded')},
                        {id: 6, label: this.$t('title.inTransit')},
                        {id: 7, label: this.$t('title.unloadRequested')},
                        {id: 8, label: this.$t('title.delivered')},
                    ],
                    loadingPoint: [],
                    unloadingPoint: [],
                },
                show: true,
                editLoad: null,
                selectedOrderId: null,
            }
        },
        mounted() {
            this.getBrands();
            this.getModels();
            this.getDrivers();
            this.getTransportVehicles();
            this.getLocations();
            this.getSupplierCarriers();
            this.getClients();
            this.getCurrencies();
            this.getSupervisors();
            this.getSupplierCompounds();
            this.getDealers();

            if (this.$route.query && this.$route.query.operation === 'edit' && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.spot')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addSpotLoad' : 'title.editSpotLoad')

                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                if ((!this.formFields.id && this.formFields.orders) || (this.formFields.id && this.editLoad.spot_load_orders.length === this.formFields.deleted_orders.length)) {
                    const errors = {orders: [this.$t('validation.minimumOrderRequired')]}
                    this.formErrors = new Error(errors)
                    return false;
                }

                if (this.validateEditSpotLoad())
                    return;

                try {
                    const params = {
                        id: this.formFields.id,
                        supplier_carrier_id: this.formFields.supplier_carrier_id,
                        supplier_compound_id: this.formFields.supplier_compound_id,
                        dealer_id: this.formFields.dealer_id,
                        deliver_to: this.formFields.deliver_to,
                        currency: this.formFields.currency,
                        comments: this.formFields.comments,
                        price: this.formFields.price,
                        _method: 'post',
                        status: this.formFields.status,
                        announce: this.formFields.announce,
                        deleted_orders: this.formFields.deleted_orders,
                        load_approver_id: this.formFields.load_approver_id,
                        new_orders: this.formFields.new_orders.map((item) => ({
                            order_id: item.id,
                            from_location_id: item.from_location.id,
                            client_id: item.client.id,
                            brand_id: item.brand.id,
                            model_id: item.model.id,
                            to_location_id: item.to_location_id,
                            spot_price: item.price,
                            errors: null
                        })),
                        orders: this.formFields.orders.map((item) => ({
                            id: item.id,
                            order_id: item.order_id,
                            to_location_id: item.to_location_id,
                            spot_price: item.price,
                            errors: null
                        })),
                    }

                    const response = await request({
                        url: 'spot/loads/update',
                        method: 'post',
                        data: {...params},
                    })

                    this.itemUpdated()
                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            validateEditSpotLoad() {
                let errors = false
                const self = this
                const formErrors = {} // supplier_carrier_id: [], currency: [], orders: [], new_orders: []
                if (!this.formFields.supplier_carrier_id) {
                    formErrors.supplier_carrier_id = [this.$t('validation.required')]
                    errors = true
                }

                if (!this.formFields.currency) {
                    formErrors.currency = [this.$t('validation.required')]
                    errors = true
                }

                _.map(this.formFields.orders, (item, index) => {
                    if (!item.to_location_id || (!item.price || item.price <= 0)) {
                        errors = true
                        formErrors.orders = [self.$t('msc.requiredFieldsMissing')]
                    }
                })

                _.map(this.formFields.new_orders, (item, index) => {
                    if (!item.to_location_id || (!item.price || item.price <= 0)) {
                        errors = true
                        formErrors.new_orders = [self.$t('msc.requiredFieldsMissing')]
                    }
                })

                this.formErrors = new Error(formErrors)
                return errors;
            },
            async handleEditClick(id) {
                this.dropdowns.orders = []
                this.formFields.new_orders = []
                this.formFields.deleted_orders = []
                this.operationTitle = this.$t('title.editLoad')

                request({url: `/spot/loads/detail/${id}`})
                    .then((response) => {
                        const {data} = response
                        this.editLoad = data
                        this.formFields.id = data.id
                        this.formFields.price = data.s_price
                        this.formFields.currency = (data.currency || {}).id
                        this.formFields.deliver_to = data.deliver_to
                        this.formFields.supplier_carrier_id = (data.supplier_carrier ? data.supplier_carrier.id : null)
                        this.formFields.supplier_compound_id = (data.supplier_compound ? data.supplier_compound.id : null)
                        this.formFields.dealer_id = (data.dealer ? data.dealer.id : null)
                        this.formFields.load_approver_id = (data.approver || {}).id
                        this.formFields.limit = data.qty
                        this.formFields.status = data.status
                        this.formFields.orders = data.spot_load_orders.map((item) => {
                            return {
                                id: item.id,
                                order_id: item.order.id,
                                client_id: item.client.id,
                                price: item.price,
                                vin_number: item.order.vin_number,
                                errors: null,
                                order: item.order,
                                brand: item.brand,
                                model: item.model,
                                client: item.client,
                                from_location: item.from_location,
                                to_location_id: item.to_location.id,
                            }
                        })

                        this.getOrders(data)
                    })
                    .catch((error) => {
                        this.handleServerError(error)
                    })
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/spot/loads/delete',
                        data: {
                            id: id,
                        },
                    })

                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.itemDeleteFailsBecsDependency()
                        return false;
                    }

                    this.itemDeleteFails()
                }
            },
            async getOrders(item) {
                try {
                    const params = {
                        ids: item.spot_load_orders.map(item => item.order.id),
                        status: [1],
                    }

                    const {data} = await request({
                        method: "post",
                        url: 'dropdowns/orders/spot/update',
                        params: params,
                        paramsSerializer: ((params) => qs.stringify(params)),
                    })

                    this.dropdowns.orders = data.map(item => ({
                        id: item.id,
                        label: `${item.order_id} | ${item.vin_number}`,
                        item: item
                    }))
                } catch (e) {

                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                    this.dropdowns.loadingPoint = data
                    this.dropdowns.unloadingPoint = data
                } catch (e) {
                    this.dropdowns.locations = []
                    this.dropdowns.loadingPoint = []
                    this.dropdowns.unloadingPoint = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async getClients() {
                try {
                    const response = await request({
                        url: '/dropdowns/clients',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.clients = data

                } catch (e) {
                    this.dropdowns.clients = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getDrivers() {
                try {
                    const response = await request({
                        url: '/dropdowns/drivers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.drivers = data
                } catch (e) {
                    this.dropdowns.drivers = []
                }
            },
            async getTransportVehicles() {
                try {
                    const response = await request({
                        url: '/dropdowns/transport/vehicles',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.vehicles = data
                } catch (e) {
                    this.dropdowns.vehicles = []
                }
            },
            async getSupervisors() {
                try {
                    const response = await request({
                        url: '/dropdowns/supervisors',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supervisors = data

                } catch (e) {
                    this.dropdowns.supervisors = []
                }
            },
            async getSupplierCompounds() {
                this.dropdowns.supplierCompounds = [];

                try {
                    const response = await request({
                        url: 'dropdowns/suppliers/compound',
                        method: 'POST',
                    })

                    const {data} = response
                    this.dropdowns.supplierCompounds = data
                } catch (e) {

                }
            },
            async getDealers() {
                this.dropdowns.dealers = [];

                try {
                    const response = await request({
                        url: 'dropdowns/dealers',
                        method: 'POST',
                    })

                    const {data} = response
                    this.dropdowns.dealers = data.map((item) => ({...item, label: `${item.dealer_id} - ${item.label}`}))
                } catch (e) {

                }
            },
            validateOrderError() {
                const errors = {}
                let hasError = false
                if (!this.selectedOrderId) {
                    errors.order_id = [this.$t('validation.required')]
                    hasError = true
                }

                if (_.find(this.editLoad.spot_load_orders, {id: this.selectedOrderId})) {
                    errors.order_id = [this.$t('validation.duplicate')]
                    hasError = true
                }

                this.formErrors = new Error(errors)
                return hasError
            },
            handleAddOrderClick() {
                if (this.validateOrderError())
                    return false

                const item = _.find(this.dropdowns.orders, {id: this.selectedOrderId}).item

                this.formFields.new_orders.push({
                    id: item.id,
                    order_id: item.id,
                    client_id: item.client.id,
                    spot_price: 0,
                    vin_number: item.vin_number,
                    order: item,
                    brand: item.brand,
                    model: item.model,
                    client: item.client,
                    from_location: item.from_location,
                    to_location_id: null,
                    errors: null,
                })

                this.selectedOrderId = null
            },
            handleDeleteNewOrderClick(orderId) {
                const orders = this.formFields.new_orders
                orders.splice(orderId, 1)
                this.formFields.new_orders = orders
            },
            handleDeleteOrderClick(orderId) {
                this.formFields.deleted_orders.push(orderId)
            },
            handleUndoDeleteClick(orderId) {
                const orders = this.formFields.deleted_orders
                orders.splice(orderId, 1)
                this.formFields.deleted_orders = _.compact(orders)
            },
            hasListAccess() {
                return this.$global.hasPermission('spotloadsview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
                this.editLoad = null
                this.selectedOrderId = null
                this.dropdowns.orders = []
                this.formFields.deleted_orders = []
                this.formFields.new_orders = []
            },
            handleLoadApprovalClick(id) {
                this.spotLoadApprovalFormFields.id = id;
                this.spotLoadApprovalFormFields.status = undefined;
                this.spotLoadApprovalFormFields.comments = null;
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .w-220 {
        width: 220px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .min-height-375 {
        min-height: 375px;
    }

    .w-100px {
        width: 100px;
    }

    .nav.nav-pills.card-header-pills.nav-fill {
        border: 1px solid #e2e2e2;
    }

    .card-header {
        background-color: rgba(0, 0, 0, .03);
    }

    .nav-item {
        background-color: white;
    }
</style>
