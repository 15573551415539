import {request} from "../../util/Request";
import moment from "moment-timezone";
import Error from "../../util/Error";
import {mapState} from "vuex";

const STATUS_FORM_STATE = {
    id: null,
    driver_id: null,
    transport_vehicle_id: null,
    estimated_unloading_time: null,
    estimated_loading_time: null,
    comments: null,
    supplier: null,
    status: null,
    _method: 'post',
}

export default {
    data() {
        return {
            statusFormFields: {...STATUS_FORM_STATE},
            selectedLoad: null,
            dropdowns: {
                supplierDrivers: [],
                supplierTransportVehicles: []
            }
        }
    },
    methods: {
        handleInTransitClick(record) {
            this.statusFormFields.status = 6
            this.statusFormFields.id = record.id
            this.statusFormFields.edit = 0
            this.statusFormFields.supplier = record.supplier_carrier.id
            this.selectedLoad = record
            this.statusFormFields.created_at = moment()
        },
        handleUnLoadClick(record) {
            this.statusFormFields.id = record.id
            this.statusFormFields.edit = 0
            this.statusFormFields.status = 7
            this.statusFormFields.supplier = record.supplier_carrier.id
            this.statusFormFields.created_at = moment()
            this.selectedLoad = record
        },
        handleAcceptClick(record) {
            this.getSupplierDrivers(record.supplier_carrier.id)
            this.getSupplierTransportVehicles(record.supplier_carrier.id)

            this.statusFormFields.edit = 0
            this.statusFormFields.status = 4
            this.statusFormFields.supplier = record.supplier_carrier.id
            this.statusFormFields.id = record.id
            this.selectedLoad = record
        },
        handleRejectClick(record) {
            this.statusFormFields.edit = 0
            this.statusFormFields.status = 3
            this.statusFormFields.supplier = record.supplier_carrier.id
            this.statusFormFields.id = record.id
            this.selectedLoad = record
        },
        handleAcceptedEditClick(record) {
            this.statusFormFields.status = 4 // just temporary
            this.statusFormFields.created_at = null // just temporary
            this.statusFormFields.edit = 1
            this.statusFormFields.supplier = record.supplier_carrier.id
            this.statusFormFields.id = record.id
            this.statusFormFields.driver_id = record.driver.id
            this.statusFormFields.transport_vehicle_id = record.transport_vehicle.id
            this.statusFormFields.estimated_unloading_time = record.estimated_unloading_time ? moment(this.$global.utcDateToLocalDate(record.estimated_unloading_time, 'YYYY-MM-DD HH:mm:ss')) : null
            this.statusFormFields.estimated_loading_time = record.estimated_loading_time ? moment(this.$global.utcDateToLocalDate(record.estimated_loading_time, 'YYYY-MM-DD HH:mm:ss')) : null
            this.selectedLoad = record
        },
        async handleSpotLoadResetClick(record) {
            if(this.global.pendingRequests > 0) {
                return false;
            }

            try {
                const response = await request({
                    url: '/spot/loads/carrier/supplier/reset',
                    method: 'post',
                    data: {
                        id: record.id
                    },
                })

                this.itemUpdated()
                this.loadList()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        async handleStatusSubmit() {
            this.formErrors = new Error({})
            const {estimated_unloading_time, estimated_loading_time, created_at} = this.statusFormFields

            try {
                const response = await request({
                    url: '/spot/loads/update/status',
                    method: 'post',
                    data: {
                        ...this.statusFormFields,
                        estimated_unloading_time: ((estimated_unloading_time) ? this.$global.dateToUtcDate(estimated_unloading_time.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                        estimated_loading_time: ((estimated_loading_time) ? this.$global.dateToUtcDate(estimated_loading_time.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                        created_at: ((created_at) ? this.$global.dateToUtcDate(created_at.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                        type: 1
                    },
                })

                this.itemUpdated()
                this.handleStatusOperationReset()
                this.loadList()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        handleStatusOperationReset() {
            this.statusFormFields = {...STATUS_FORM_STATE}
            this.selectedLoad = null
            this.dropdowns = {
                supplierDrivers: [],
                supplierTransportVehicles: []
            }
        },
        handleAfterQuickDriverCreated(inputs) {
            this.dropdowns.drivers.push({id: inputs.id, label: inputs.name})
            this.supplierDrivers.drivers.push({id: inputs.id, label: inputs.name})
            this.statusFormFields.driver_id = inputs.id;
        },
        handleAfterQuickDriverUpdated(inputs) {
            const index = _.findIndex(this.dropdowns.drivers, {id: inputs.id})
            this.$set(this.dropdowns.drivers[index], 'label', `${inputs.name}`);
            const sIndex = _.findIndex(this.dropdowns.drivers, {id: inputs.id})
            this.$set(this.dropdowns.supplierDrivers[sIndex], 'label', `${inputs.name}`);
        },
        handleAfterQuickTransportVehicleCreated(inputs) {
            this.dropdowns.transportVehicles.push({
                id: inputs.id,
                label: inputs.brand + ', ' + inputs.truck_plate_number + " | " + inputs.trailer_plate_number
            })
            this.dropdowns.supplierTransportVehicles.push({
                id: inputs.id,
                label: inputs.brand + ', ' + inputs.truck_plate_number + " | " + inputs.trailer_plate_number
            })
            this.statusFormFields.transport_vehicle_id = inputs.id;
        },
        handleAfterQuickTransportVehicleUpdated(inputs) {
            const index = _.findIndex(this.dropdowns.transportVehicles, {id: inputs.id})
            this.$set(this.dropdowns.transportVehicles[index], 'label', `${inputs.brand + ', ' + inputs.truck_plate_number + " | " + inputs.trailer_plate_number}`);
            const sIndex = _.findIndex(this.dropdowns.supplierTransportVehicles, {id: inputs.id})
            this.$set(this.dropdowns.supplierTransportVehicles[sIndex], 'label', `${inputs.brand + ', ' + inputs.truck_plate_number + " | " + inputs.trailer_plate_number}`);
        },
        async getSupplierDrivers(supplier_id) {
            this.dropdowns.supplierDrivers = [];
            try {
                const response = await request({
                    url: '/dropdowns/drivers',
                    method: "post",
                    data: {
                        supplier_id: supplier_id
                    }
                })

                const {data} = response
                this.dropdowns.supplierDrivers = data
            }catch (e) {
                this.dropdowns.supplierDrivers = [];
            }
        },
        async getSupplierTransportVehicles(supplier_id) {
            this.dropdowns.supplierTransportVehicles = [];
            try {
                const response = await request({
                    url: '/dropdowns/transport/vehicles',
                    method: "post",
                    data: {
                        supplier_id: supplier_id
                    }
                })

                const {data} = response
                this.dropdowns.supplierTransportVehicles = data
            }catch (e) {
                this.dropdowns.supplierTransportVehicles = [];
            }
        }
    },
    computed: {
        ...mapState([
            'global',
        ]),
        title() {
            if (this.statusFormFields.status == 6) {
                return this.$t('title.loadInTransit')
            } else if (this.statusFormFields.status == 7) {
                return this.$t('title.loadUnloaded')
            } else if (this.statusFormFields.status == 4) {
                return this.$t('title.acceptLoad')
            } else if (this.statusFormFields.status == 3) {
                return this.$t('title.rejectLoad')
            }
        }
    }
}
