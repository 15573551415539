import {request} from "../../util/Request";
import Error from "../../util/Error";

const SPOT_LOAD_APPROVAL_FORM_STATE = {
    id: null,
    comments: null,
    status: undefined,
}

export default {
    data() {
        return {
            spotLoadApprovalFormFields: {...SPOT_LOAD_APPROVAL_FORM_STATE}
        }
    },
    methods: {
        async handleLoadApprovalSubmit() {
            try {
                const response = await request({
                    url: 'spot/loads/load/approval',
                    method: 'post',
                    data: {...this.spotLoadApprovalFormFields}
                })

                this.spotLoadApprovalFormFields = {...SPOT_LOAD_APPROVAL_FORM_STATE}
                this.loadList()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
    }
}
